import React, { useContext, useReducer } from "react";

const WindowHeightContext = React.createContext();

export const useWindowHeight = () => {
  return useContext(WindowHeightContext);
};

const SET_HEIGHT = "SET_HEIGHT";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_HEIGHT:
      return { ...state, height: action.height };
    default:
      return state;
  }
};

export const WindowHeightProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    height: window.innerHeight,
  });

  const setHeight = (height) => dispatch({ type: SET_HEIGHT, height: height });

  return (
    <WindowHeightContext.Provider
      value={{
        height: state.height,
        setHeight,
      }}
    >
      {children}
    </WindowHeightContext.Provider>
  );
};
