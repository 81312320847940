import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { WindowWidthProvider } from "./General/Context/WindowWidthContext";
import { WindowHeightProvider } from "./General/Context/WindowHeightContext";
import { ComponentSizesProvider } from "./General/Context/ComponentSizesContext";
import { WheelDirectionProvider } from "./General/Context/WheelDirectionContext";
import { HashChangeProvider } from "./General/Context/HashChangeContext";
import { IsWindowInFocusProvider } from "./General/Context/IsWindowInFocusContext";
import { HashInInitialUrlProvider } from "./General/Context/HashInInitialUrlContext";
import { ErrorBoundary } from "./General/ErrorBoundary";
import "./General/loader.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Loader } from "./General/Loader";
import { NUMBER_OF_LOADED_COMPONENTS } from "./General/Loader";
import { IsPageInTopProvider } from "./General/Context/IsPageInTopContext";

const Root = lazy(() =>
  new Promise(async (resolve) => {
    const module = await import("./Root");
    NUMBER_OF_LOADED_COMPONENTS.inc();
    resolve(module);
  }).catch((e) => {
    alert("Asynchronous error");
  })
);
/* 
const WindowWidthProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/WindowWidthContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.WindowWidthProvider});
    })
);

const WindowHeightProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/WindowHeightContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.WindowHeightProvider});
    })
);

const ComponentSizesProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/ComponentSizesContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.ComponentSizesProvider});
    })
);

const WheelDirectionProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/WheelDirectionContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.WheelDirectionProvider});
    })
);


const HashChangeProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/HashChangeContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.HashChangeProvider});
    })
);

const IsWindowInFocusProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/IsWindowInFocusContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.IsWindowInFocusProvider});
    })
);

const HashInInitialUrlProvider = lazy(
  () =>
    new Promise(async (resolve) => {
      const module = await import("./General/Context/HashInInitialUrlContext");
      NUMBER_OF_LOADED_COMPONENTS.inc();
      resolve({default:module.HashInInitialUrlProvider});
    })
);

 */
const rootElement = document.getElementById("root");

//<React.StrictMode>
//</React.StrictMode>
ReactDOM.unstable_createRoot(rootElement).render(
  <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <ComponentSizesProvider>
        <WindowWidthProvider>
          <WindowHeightProvider>
            <WheelDirectionProvider>
              <HashChangeProvider>
                <HashInInitialUrlProvider>
                  <IsWindowInFocusProvider>
                    <IsPageInTopProvider>
                      <Root />
                    </IsPageInTopProvider>
                  </IsWindowInFocusProvider>
                </HashInInitialUrlProvider>
              </HashChangeProvider>
            </WheelDirectionProvider>
          </WindowHeightProvider>
        </WindowWidthProvider>
      </ComponentSizesProvider>
    </Suspense>
  </ErrorBoundary>
);
/*  ReactDOM.unstable_createRoot(rootElement).render(
  <ComponentSizesProvider>
    <WindowWidthProvider>
      <WindowHeightProvider>
        <WheelDirectionProvider>
          <HashChangeProvider>
            <Root />
          </HashChangeProvider>
        </WheelDirectionProvider>
      </WindowHeightProvider>
    </WindowWidthProvider>
  </ComponentSizesProvider>
);  */
/* ReactDOM.render(
  <React.StrictMode>
      <Bookmarks />
  </React.StrictMode>,
  rootElement
); */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
serviceWorkerRegistration.unregister();
//serviceWorkerRegistration.register();
