import React, { useContext, useReducer } from "react";

const HashChangeContext = React.createContext();

export const useHashChange = () => {
  return useContext(HashChangeContext);
};

const SET_HASH = "SET_HASH";

const reducer = (state, action) => {
  
  switch (action.type) {
    case SET_HASH:
      return { ...state, hash: action.hash };
    default:
      return state;
  }
};

export const HashChangeProvider = ({ children }) => {
  

  const [state, dispatch] = useReducer(reducer, { hash: window.location.hash.substr(1) });

  const setHash = (hash) => dispatch({ type: SET_HASH, hash: hash });

  
  return (
    <HashChangeContext.Provider
      value={{
        hash: state.hash,
        setHash,
      }}
    >
      {children}
    </HashChangeContext.Provider>
  );
};
