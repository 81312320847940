import React, { useContext, useReducer } from "react";

const IsWindowInFocusContext = React.createContext();

export const useIsWindowInFocus = () => {
  return useContext(IsWindowInFocusContext);
};

const SET_FOCUS = "SET_FOCUS";
const SET_BLUR = "SET_BLUR";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FOCUS:
      return { ...state, status: true };
    case SET_BLUR:
      return { ...state, status: false };
    default:
      return state;
  }
};

export const IsWindowInFocusProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { status: true });

  const focus = () => dispatch({ type: SET_FOCUS });
  const blur = () => dispatch({ type: SET_BLUR });

  return (
    <IsWindowInFocusContext.Provider
      value={{
        status: state.status,
        focus,
        blur,
      }}
    >
      {children}
    </IsWindowInFocusContext.Provider>
  );
};
