import React, { useContext, useReducer } from "react";

const IsPageInTopContext = React.createContext();

export const useIsPageInTop = () => {
  return useContext(IsPageInTopContext);
};

const SET_TOP = "SET_TOP";
const SET_NOT_TOP = "SET_NOT_TOP";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TOP:
      return { ...state, status: true };
    case SET_NOT_TOP:
      return { ...state, status: false };
    default:
      return state;
  }
};

export const IsPageInTopProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { status: true });

  const top = () => dispatch({ type: SET_TOP });
  const notTop = () => dispatch({ type: SET_NOT_TOP });

  return (
    <IsPageInTopContext.Provider
      value={{
        status: state.status,
        top,
        notTop,
      }}
    >
      {children}
    </IsPageInTopContext.Provider>
  );
};
