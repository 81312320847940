import React, { useContext, useState } from "react";

const HashInInitialUrlContext = React.createContext();

export const useHashInInitialUrl = () => {
  return useContext(HashInInitialUrlContext);
};

export const HashInInitialUrlProvider = ({ children }) => {
  const [hashInInitialUrl, setHashInInitialUrl] = useState(
    window.location.hash.substr(1) ? window.location.hash.substr(1) : undefined
  );
  const reset = () => setHashInInitialUrl(undefined);

  /* let hashInInitialUrl = undefined;

   if (window.location.hash.substr(1)) {
    hashInInitialUrl = window.location.hash.substr(1);
    
  }
 */
  return (
    <HashInInitialUrlContext.Provider
      value={{
        hash: hashInInitialUrl,
        reset,
      }}
    >
      {children}
    </HashInInitialUrlContext.Provider>
  );
};
