import React, { useContext, useReducer } from "react";

const ComponentSizesContext = React.createContext();

export const useComponentSizes = () => {
  return useContext(ComponentSizesContext);
};

const TOP_APP_BAR_HEIGHTS = { 0: 56, 600: 64 };
const TOP_SIGN_IN_APP_BAR_HEIGHTS = { 0: 58, 600: 66 };
const TOP_TITLE_LISTS_APP_BAR_HEIGHTS = { 0: 50, 600: 50 };
const TOP_TABS_MENU_HEIGHTS = { 0: 47, 600: 47 };
const UPDATED_WIDTH = "UPDATED_WIDTH";

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATED_WIDTH: {
      
      let topAppBarHeight = TOP_APP_BAR_HEIGHTS[0];
      let topSignInAppBarHeight = TOP_SIGN_IN_APP_BAR_HEIGHTS[0];
      let topTitleListsAppBarHeight = TOP_TITLE_LISTS_APP_BAR_HEIGHTS[0];
      let topTabsMenuHeight = TOP_TABS_MENU_HEIGHTS[0];

      if (action.width > 600) {
        topAppBarHeight = TOP_APP_BAR_HEIGHTS[600];
        topSignInAppBarHeight = TOP_SIGN_IN_APP_BAR_HEIGHTS[600];
        topTitleListsAppBarHeight = TOP_TITLE_LISTS_APP_BAR_HEIGHTS[600];
        topTabsMenuHeight = TOP_TABS_MENU_HEIGHTS[600];
      }
      return {
        ...state,
        topAppBarHeight: topAppBarHeight,
        topSignInAppBarHeight: topSignInAppBarHeight,
        topTitleListsAppBarHeight: topTitleListsAppBarHeight,
        topTabsMenuHeight: topTabsMenuHeight,
      };
    }
    default:
      return state;
  }
};

export const ComponentSizesProvider = ({ children }) => {
  

  const [state, dispatch] = useReducer(reducer, {
    topAppBarHeight:
      window.innerWidth > 600
        ? TOP_APP_BAR_HEIGHTS[600]
        : TOP_APP_BAR_HEIGHTS[0],
    topSignInAppBarHeight:
      window.innerWidth > 600
        ? TOP_SIGN_IN_APP_BAR_HEIGHTS[600]
        : TOP_SIGN_IN_APP_BAR_HEIGHTS[0],
    topTitleListsAppBarHeight:
      window.innerWidth > 600
        ? TOP_TITLE_LISTS_APP_BAR_HEIGHTS[600]
        : TOP_TITLE_LISTS_APP_BAR_HEIGHTS[0],
    topTabsMenuHeight:
      window.innerWidth > 600
        ? TOP_TABS_MENU_HEIGHTS[600]
        : TOP_TABS_MENU_HEIGHTS[0],
  });

  const updatedWidth = (width) =>
    dispatch({ type: UPDATED_WIDTH, width: width });
    

  return (
    <ComponentSizesContext.Provider
      value={{
        topAppBarHeight: state.topAppBarHeight,
        topSignInAppBarHeight: state.topSignInAppBarHeight,
        topTitleListsAppBarHeight: state.topTitleListsAppBarHeight,
        topTabsMenuHeight: state.topTabsMenuHeight,
        updatedWidth,
      }}
    >
      {children}
    </ComponentSizesContext.Provider>
  );
};
