import React, { useContext, useReducer } from "react";

const WheelDirectionContext = React.createContext();

export const useWheelDirection = () => {
  return useContext(WheelDirectionContext);
};

const SET_DIRECTION = "SET_DIRECTION";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_DIRECTION:
      return { ...state, direction: action.direction };
    default:
      return state;
  }
};

export const WheelDirectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    direction: "down",
  });

  const setDirection = (direction) =>
    dispatch({ type: SET_DIRECTION, direction: direction });

  return (
    <WheelDirectionContext.Provider
      value={{
        direction: state.direction,
        setDirection,
      }}
    >
      {children}
    </WheelDirectionContext.Provider>
  );
};
