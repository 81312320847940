import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export var NUMBER_OF_LOADED_COMPONENTS = {
  value: 5,
  inc() {
    this.value++;
  },
  reset() {
    this.value = 5;
  },
  count() {
    return this.value;
  },
};

export var NUMBER_COMP2 = {
  value: 1,
  letMeKnow() {
    //document.getElementById("xloader").innerText = this.testVar;
  },
  get testVar() {
    return this.value;
  },
  set testVar(value) {
    this.value = this.value + 1;
    this.letMeKnow();
  },
};

export const Loader1 = () => {
  //const componentLoader = useComponentLoader();

  return (
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <span id="xloader">{NUMBER_COMP2.testVar}</span>
    </div>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function Loader() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(NUMBER_OF_LOADED_COMPONENTS.count() * 10);
    }, 1000);
    return () => {
      clearInterval(timer);
      NUMBER_OF_LOADED_COMPONENTS.reset()
      setProgress(0);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        margin: "0 auto",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      {progress > 0 && <CircularProgressWithLabel value={progress} />}
    </div>
  );
}
