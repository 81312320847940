import React, { useContext, useReducer } from "react";

const WindowWidthContext = React.createContext();

export const useWindowWidth = () => {
  return useContext(WindowWidthContext);
};

const SET_WIDTH = "SET_WIDTH";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_WIDTH:
      return { ...state, width: action.width };
    default:
      return state;
  }
};

export const WindowWidthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    width: window.innerWidth,
  });

  const setWidth = (width) => dispatch({ type: SET_WIDTH, width: width });

  return (
    <WindowWidthContext.Provider
      value={{
        width: state.width,
        setWidth,
      }}
    >
      {children}
    </WindowWidthContext.Provider>
  );
};
